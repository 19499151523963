<template>
  <el-row type="flex" align="middle" :justify="justify">
    <span style="margin-right: 8px">{{ txid | coverWithEllipsis }}</span>
    <el-button circle size="mini" icon="el-icon-copy-document" @click="handleCopy(txid)" />
  </el-row>
</template>

<script>
export default {
  props: {
    txid: {
      type: String,
      default: "",
    },
    justify: {
      type: String,
      default: "center",
    },
  },
  filters: {
    coverWithEllipsis: function(value) {
      if (!value || value.length <= 10) {
        return value;
      }

      const start = value.substring(0, 6);
      const end = value.substring(value.length - 4);
      return `${start}...${end}`;
    },
  },
  methods: {
    handleCopy(text) {
      this.$copyText(text).then(() => {
        this.$message.success("复制成功");
      });
    },
  },
};
</script>
