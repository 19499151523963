<template>
  <div style="padding: 20px">
    <div>
      <el-form :model="queryForm" :inline="true">
        <el-form-item label="铸造人ucid">
          <el-input v-model="queryForm.creator_ucid" placeholder="请输入" />
        </el-form-item>
        <!-- <el-form-item label="提交审核人ucid">
          <el-input v-model="queryForm.publish_ucid" placeholder="请输入" />
        </el-form-item> -->
        <el-form-item label="铸造者手机">
          <el-input v-model="queryForm.creator_mobile" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="铸造者邮箱">
          <el-input v-model="queryForm.creator_email" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="所属分类">
          <el-select v-model="queryForm.category_id" placeholder="请选择" clearable>
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :label="item.title_cn"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="NFT合集名称">
          <el-input v-model="queryForm.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="TXID">
          <el-input v-model="queryForm.txid" placeholder="请输入" />
        </el-form-item>
        <!-- <el-form-item label="提交审核开始时间">
          <el-date-picker
            v-model="queryForm.start_at"
            value-format="timestamp"
            type="datetime"
            placeholder="选择开始时间"
          />
        </el-form-item>
        <el-form-item label="提交审核结束时间">
          <el-date-picker
            v-model="queryForm.end_at"
            value-format="timestamp"
            type="datetime"
            placeholder="选择结束时间"
          />
        </el-form-item> -->
        <el-form-item label="是否黑名单">
          <el-select v-model="queryForm.in_black_list" placeholder="请选择" clearable>
            <el-option label="全部" :value="0" />
            <el-option label="是" :value="1" />
            <el-option label="否" :value="2" />
          </el-select>
        </el-form-item>

        <el-form-item label="NFT类型">
          <el-select v-model="queryForm.work_type" placeholder="请选择" clearable>
            <el-option label="图片" value="1" />
            <el-option label="音乐" value="2" />
          </el-select>
        </el-form-item>

        <el-form-item label="上架范围">
          <el-select v-model="queryForm.on_shelf_area" placeholder="请选择" clearable>
            <el-option label="国内" value="0" />
            <el-option label="海外" value="1" />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleClickSearch">查询</el-button>
          <el-button type="info" @click="handleClickReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-radio-group @change="handleStatusChange" v-model="status" style="margin-bottom: 10px;">
        <el-radio-button label="all">全部</el-radio-button>
        <el-radio-button label="waiting">待审核</el-radio-button>
        <el-radio-button label="pass">已通过</el-radio-button>
        <el-radio-button label="reject">已拒绝</el-radio-button>
      </el-radio-group>
    </div>

    <el-table
      :data="list"
      border
      stripe
      @selection-change="handleSelectionChange"
      row-key="nft_tx_id"
    >
      <el-table-column v-if="selectableStatusList.includes(status)" type="selection" width="60">
      </el-table-column>
      <el-table-column label="NFT合集名称" prop="name" width="120px" />
      <el-table-column label="预览图" prop="pics" width="160px">
        <template slot-scope="scope">
          <el-image
            :src="scope.row.cover"
            fit="fill"
            :lazy="true"
            :preview-src-list="[scope.row.cover]"
            style="max-width: 140px; max-height: 140px; margin: 0 auto;"
          />
        </template>
      </el-table-column>
      <el-table-column label="作品类型" prop="work_type" />
      <el-table-column label="txid" prop="txid" width="150px">
        <template slot-scope="scope">
          <CopyTxid :txid="scope.row.txid" />
        </template>
      </el-table-column>

      <el-table-column label="铸造者信息" prop="creator">
        <template slot-scope="scope">
          <el-button type="text" @click="handleQueryUser(scope.row.creator_ucid)">
            {{ scope.row.creator }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="提交审核时间" prop="created_at" width="160px">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="status" width="80px">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 'pass'" type="success">已通过</el-tag>
          <el-tag v-if="scope.row.status === 'reject'" type="danger">已拒绝</el-tag>
          <el-tag v-if="scope.row.status === 'waiting'" type="info">待审核</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="藏品数量" prop="number" width="80px" />
      <!-- TODO 隐藏部分 -->
      <!-- <el-table-column label="上架价格" prop="amount_str" width="130px" /> -->
      <el-table-column label="关联批次" prop="batch_title" width="130px" />

      <el-table-column label="所属分类" prop="category_name" width="100px" />
      <el-table-column label="黑名单" prop="in_black_list" width="80px">
        <template slot-scope="scope">
          <div>{{ scope.row.in_black_list ? "是" : "否" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="定时通过的时间" prop="on_sell_at" width="160px">
        <template slot-scope="scope">
          <div>{{ scope.row.on_sell_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="上架范围" prop="on_shelf_area" />
      <el-table-column label="驳回原因" prop="reason" />
      <el-table-column label="操作" align="center" width="220px">
        <template slot-scope="scope">
          <SingleOptions :audit="scope.row" @success="handleSuccess" />
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 10px" v-if="selectableStatusList.includes(status)">
      <MultiOptions :list="multipleSelection" @success="handleSuccess" />
    </div>

    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getNFTAuditRecords, getNFTCategoryList } from "@/api/nft.js";
import SingleOptions from "./options.vue";
import MultiOptions from "./multiOptions.vue";
import CopyTxid from "@/components/CopyTxid.vue";
import dayjs from "dayjs";

export default {
  components: {
    SingleOptions,
    MultiOptions,
    CopyTxid,
  },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      // 查询参数
      queryForm: {},
      status: "all",
      // 分类列表
      categoryList: [],

      selectableStatusList: ["waiting", "pass"],
      multipleSelection: [],
    };
  },
  filters: {
    formatDate: function(value) {
      if (+value > 0) {
        return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "--";
      }
    },
  },
  mounted() {
    this.fetchAuditRecords();
    this.fetchCategoryList();
  },
  methods: {
    async fetchCategoryList() {
      const { data } = await getNFTCategoryList({ page: 1, page_size: 20 });
      console.log(data);
      if (Array.isArray(data?.data.items)) {
        this.categoryList = [...(data?.data.items || [])];
      }
    },
    getSearchParams() {
      const params = {
        ...this.queryForm,
        page: this.page,
        page_size: this.pageSize,
      };

      if (this.status !== "all") {
        params.status = this.status;
      }
      if (params.start_at) {
        params.start_at = Math.floor(params.start_at / 1000);
      }
      if (params.end_at) {
        params.end_at = Math.floor(params.end_at / 1000);
      }

      ["in_black_list", "start_at", "end_at"].forEach((key) => {
        if (params[key] === "") {
          delete params[key];
        }
      });
      return params;
    },
    async fetchAuditRecords() {
      try {
        const params = this.getSearchParams();
        const end_at = params.end_at;
        const start_at = params.start_at;

        if (start_at > end_at) {
          this.$message.error("开始时间不能大于结束时间");
          return;
        }
        const { data } = await getNFTAuditRecords(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },

    handleQueryUser(ucid) {
      this.queryForm = {
        ...this.queryForm,
        creator_ucid: ucid,
      };
      this.page = 1;
      this.pageSize = 20;
      this.fetchAuditRecords();
    },
    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchAuditRecords();
    },

    handleClickReset() {
      this.queryForm = {};
      this.page = 1;
      this.pageSize = 20;
      this.fetchAuditRecords();
    },

    handleSuccess() {
      this.fetchAuditRecords();
    },

    handleCurrentChange(page) {
      this.page = page;
      this.fetchAuditRecords();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchAuditRecords();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchAuditRecords();
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="less" scoped></style>
