<template>
  <div>
    <div>
      <el-button
        size="mini"
        type="success"
        @click="handleClickPass"
        :loading="passLoading"
        v-if="audit.status === 'waiting'"
        style="margin: 0 8px 8px 0"
      >
        通过
      </el-button>
      <el-button
        size="mini"
        type="danger"
        @click="visible = true"
        :loading="rejectLoading"
        v-if="audit.status === 'waiting'"
        style="margin: 0 8px 8px 0"
      >
        驳回
      </el-button>
      <el-button
        size="mini"
        type="danger"
        @click="visible = true"
        :loading="rejectLoading"
        v-if="audit.status === 'pass'"
        style="margin: 0 8px 8px 0"
      >
        下架驳回
      </el-button>
      <el-button
        v-if="!!audit.txid"
        type="primary"
        size="mini"
        @click="handleOpenDetail"
        style="margin: 0 8px 8px 0"
      >
        详情
      </el-button>
      <el-button
        v-if="audit.in_black_list"
        type="success"
        size="mini"
        @click="handleRemoveFromBlockList"
        style="margin: 0 8px 8px 0"
      >
        移除黑名单
      </el-button>
    </div>
    <el-dialog
      title="是否通过审核-"
      :visible.sync="passDialogVisible"
      width="540px"
      @close="passDialogVisible = false"
      center
    >
      <div style="text-align: left">
        <AuditCard :audit="audit" />
        <div>
          <el-radio v-model="auditType" label="new">上新批次</el-radio>
          <el-radio v-model="auditType" label="compose">合成批次</el-radio>
        </div>

        <div v-if="auditType === 'new'">
          <div style="margin: 16px 0">
            <span style="color: #909399; margin-right: 12px;">请先选择上架批次</span>
            <el-select placeholder="请选择上新批次" v-model="selectedBatch">
              <el-option label="无-正常上架" :value="0"> </el-option>
              <el-option
                v-for="batch in batchList"
                :label="batch.title"
                :value="batch.batch_number"
                :key="batch.batch_number"
              />
            </el-select>
          </div>
          <div v-if="selectedBatch > 0">
            <DisplayTime
              :onSellAt="selectedBatchData.on_sell_at"
              :priorityPurchaseAt="selectedBatchData.priority_purchase_at"
              :reservePurchaseStart="selectedBatchData.reserve_purchase_start"
              :reservePurchaseEnd="selectedBatchData.reserve_purchase_end"
            />
          </div>
        </div>

        <div v-else>
          <div style="margin: 16px 0">
            <span style="color: #909399; margin-right: 12px;">请先选择合成批次</span>
            <el-select placeholder="请选择合成批次" v-model="selectedComposeBatch">
              <el-option
                v-for="batch in composeBatchList"
                :label="batch.name"
                :value="batch.id"
                :key="batch.id"
              />
            </el-select>
          </div>
          <div v-if="selectedComposeBatch">
            <div>合成开启/结束时间</div>
            <div>{{ selectedComposeBatchData.start_at_str }}</div>
            <div>{{ selectedComposeBatchData.end_at_str }}</div>
          </div>
        </div>

        <div style="margin: 16px 0">
          <span style="color: #909399; margin-right: 12px;">请先选择上架分类</span>
          <el-select placeholder="请选择上架分类" v-model="selectedCategory">
            <el-option
              v-for="item in categoryList"
              :label="item.title_cn"
              :value="item.id"
              :key="item.id"
            />
          </el-select>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="passDialogVisible = false">取消</el-button>
        <el-button v-if="auditType === 'new'" type="primary" @click="handlePass">
          通过审核
        </el-button>
        <el-button v-else type="primary" @click="handlePassCompose">通过审核</el-button>
      </span>
    </el-dialog>

    <el-dialog title="驳回原因填写" :visible.sync="visible" width="30%" @close="visible = false">
      <SelectRejectMsg
        :username="audit.name"
        type="audit"
        :loading="rejectLoading"
        @confirm="handleReject"
        @cancel="visible = false"
        showBlockList
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  setNFTAuditRecord,
  setNFTAuditBlockList,
  getBatchList,
  getComposeBatches,
  auditNFT2ComposeBatch,
  getNFTCategoryList,
} from "@/api/nft.js";
import SelectRejectMsg from "@/components/SelectRejectMsg.vue";
import AuditCard from "./AuditCard.vue";
import config from "@/configs/index";
import dayjs from "dayjs";
import DisplayTime from "@/components/DisplayTime.vue";

export default {
  components: {
    SelectRejectMsg,
    AuditCard,
    DisplayTime,
  },
  props: {
    audit: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    dateFormater: function(value) {
      if (!value || +value === 0) {
        return "-";
      }
      return dayjs(+value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  computed: {
    selectedBatchData() {
      return this.batchList.find((i) => i.batch_number === this.selectedBatch);
    },
    selectedComposeBatchData() {
      return this.composeBatchList.find((i) => i.id === this.selectedComposeBatch);
    },
  },
  data() {
    return {
      passLoading: false,
      rejectLoading: false,
      visible: false,
      passDialogVisible: false,
      // useTime: false,
      time: null,
      selectedBatch: null,
      selectedCategory: null,
      batchList: [],

      selectedComposeBatch: null,
      composeBatchList: [],
      categoryList: [],

      auditType: "new",
    };
  },
  methods: {
    async fetchBatchList() {
      try {
        const { data } = await getBatchList({
          page: 1,
          pageSize: 20,
        });
        this.batchList = data.data.items || [];
      } catch (error) {
        this.batchList = [];
      }
    },
    async fetchComposeBatchList() {
      try {
        const { data } = await getComposeBatches();
        this.composeBatchList = data.data.batches || [];
      } catch (error) {
        this.composeBatchList = [];
      }
    },
    async fetchCategoryList() {
      try {
        const { data } = await getNFTCategoryList({
          page: 1,
          page_size: 1000,
        });
        this.categoryList = data.data.items;
        console.log(data.data.items);
      } catch (error) {
        this.categoryList = [];
        console.log(error);
      }
    },
    handleClickPass() {
      this.passDialogVisible = true;
      this.selectedCategory = null;
      this.fetchBatchList();
      this.fetchComposeBatchList();
      this.fetchCategoryList();
    },
    async handlePass() {
      if (this.passLoading) {
        return;
      }

      this.passLoading = true;
      try {
        const params = {
          txids: [this.audit.txid],
          status: "pass",
          batch_number: this.selectedBatch,
          category_id: this.selectedCategory,
        };
        await setNFTAuditRecord(params);
        this.$emit("success");

        this.passLoading = false;
        this.passDialogVisible = false;
      } catch (error) {
        this.passLoading = false;
      }
    },
    async handlePassCompose() {
      try {
        const params = {
          on_shelf_ids: [this.audit.record_id],
          batch_id: this.selectedComposeBatch,
        };

        await auditNFT2ComposeBatch(params);
        this.$emit("success");

        this.passLoading = false;
        this.passDialogVisible = false;
      } catch (error) {
        console.log(error);
      }
    },
    async handleReject(msg, isBlock) {
      if (this.rejectLoading) {
        return;
      }

      this.rejectLoading = true;

      try {
        const params = {
          txids: [this.audit.txid],
          status: "reject",
          msg,
          in_black_list: isBlock,
        };

        await setNFTAuditRecord(params);
        this.$emit("success");
        this.rejectLoading = false;
        this.visible = false;
      } catch (error) {
        this.rejectLoading = false;
      }
    },
    async handleRemoveFromBlockList() {
      await setNFTAuditBlockList({
        txids: [this.audit.txid],
        in_black_list: false,
      });
      this.$emit("success");
    },
    handleOpenDetail() {
      if (!this.audit.txid) {
        return;
      }
      const txid = this.audit.txid.split("_")[0] || "";
      if (txid) {
        window.open(`${config.nftLiteUrl}/nft?txid=${txid}`);
      }
    },
  },
};
</script>
