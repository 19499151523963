<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="10" :offset="0">
        <div style=" color: #909399; text-align: right;">
          预约购开启/结束时间
        </div>
      </el-col>
      <el-col :span="14" :offset="0">
        <div>{{ reservePurchaseStart | dateFormater }}</div>
        <div>{{ reservePurchaseEnd | dateFormater }}</div>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 6px">
      <el-col :span="10" :offset="0">
        <div style=" color: #909399; text-align: right;">
          优先购开启时间
        </div>
      </el-col>
      <el-col :span="14" :offset="0">
        <span>{{ priorityPurchaseAt | dateFormater }}</span>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 6px">
      <el-col :span="10" :offset="0">
        <div style=" color: #909399; text-align: right;">上新时间</div>
      </el-col>
      <el-col :span="14" :offset="0">
        <span>{{ onSellAt | dateFormater }}</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  props: {
    onSellAt: {
      type: Number,
      default: 0,
    },
    priorityPurchaseAt: {
      type: Number,
      default: 0,
    },
    reservePurchaseStart: {
      type: Number,
      default: 0,
    },
    reservePurchaseEnd: {
      type: Number,
      default: 0,
    },
  },
  filters: {
    dateFormater: function(value) {
      if (!value || +value === 0) {
        return "-";
      }
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
};
</script>
