<template>
  <div class="audit-card-container">
    <div class="audit-card">
      <div style="width: 120px; height: 120px">
        <el-image
          v-if="audit.cover"
          style="width: 120px; height: 120px"
          :src="audit.cover"
          fit="cover"
        />
      </div>
      <div class="audit-card-info">
        <h3>{{ audit.name }}</h3>
        <div>
          <span style=" color: #909399;">铸造数量：</span>
          <span style="color: #111;">{{ audit.number || 0 }}</span>
        </div>
        <div>
          <span style=" color: #909399;">上架数量：</span>
          <span style="color: #111;">{{ audit.on_shelf_number || 0 }}</span>
        </div>
        <div>
          <span style=" color: #909399;">上架价格：</span>
          <span style="color: #111;">{{ audit.fiat_amount_str || "-" }}</span>
        </div>
        <!-- <el-row :gutter="8">
          <el-col :span="8" :offset="0">
            <span style="margin-right: 6px">铸造作品数</span>
            <span>{{ audit.number || 0 }}</span>
          </el-col>
          <el-col :span="8" :offset="0">
            <span style="margin-right: 6px">申请上架数量</span>
            <span>{{ audit.on_shelf_number || 0 }}</span>
          </el-col>
          <el-col :span="8" :offset="0">
            <span style="margin-right: 6px">上架价格</span>
            <span>{{ audit.fiat_amount_str || "-" }}</span>
          </el-col>
        </el-row> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    audit: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="less" scoped>
.audit-card {
  display: flex;
  margin-bottom: 12px;
  .audit-card-info {
    margin-left: 20px;
    width: 100%;

    h3 {
      margin: 0 0 10px 0;
      font-size: 20px;
    }
  }
}
</style>
