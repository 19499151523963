<template>
  <div>
    <el-button :disabled="list.length <= 0" type="success" @click="handleClickPass">
      批量通过
    </el-button>
    <el-button :disabled="list.length <= 0" type="danger" @click="visible = true">
      批量驳回
    </el-button>

    <el-dialog
      title="是否通过审核"
      :visible.sync="passDialogVisible"
      width="540px"
      @close="passDialogVisible = false"
      center
    >
      <div style="text-align: left">
        <div style="height: 300px; overflow-y: scroll; overflow-x: hidden">
          <AuditCard v-for="item in list" :key="item.record_id" :audit="item" />
        </div>
        <div style="margin: 16px 0">
          <el-radio v-model="auditType" label="new">上新批次</el-radio>
          <el-radio v-model="auditType" label="compose">合成批次</el-radio>
        </div>
        <div v-if="auditType === 'new'">
          <div style="margin: 16px 0">
            <span style="color: #909399; margin-right: 12px;">请先选择上架批次</span>
            <el-select placeholder="请选择批次" v-model="selectedBatch">
              <el-option label="无-正常上架" :value="0"> </el-option>
              <el-option
                v-for="batch in batchList"
                :label="batch.title"
                :value="batch.batch_number"
                :key="batch.batch_number"
              />
            </el-select>
          </div>
          <div v-if="selectedBatch > 0">
            <el-row :gutter="20">
              <el-col :span="8" :offset="0">
                <div style="margin-right: 12px; color: #909399; text-align: right;">
                  预约购开启/结束时间
                </div>
              </el-col>
              <el-col :span="16" :offset="0">
                <div>{{ selectedBatchData.reserve_purchase_start | dateFormater }}</div>
                <div>{{ selectedBatchData.reserve_purchase_end | dateFormater }}</div>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 10px">
              <el-col :span="8" :offset="0">
                <div style="margin-right: 12px; color: #909399; text-align: right;">
                  优先购开启时间
                </div>
              </el-col>
              <el-col :span="16" :offset="0">
                <span>{{ selectedBatchData.priority_purchase_at | dateFormater }}</span>
              </el-col>
            </el-row>
            <el-row :gutter="20" style="margin-top: 10px">
              <el-col :span="8" :offset="0">
                <div style="margin-right: 12px; color: #909399; text-align: right;">上新时间</div>
              </el-col>
              <el-col :span="16" :offset="0">
                <span>{{ selectedBatchData.on_sell_at | dateFormater }}</span>
              </el-col>
            </el-row>
          </div>
        </div>

        <div v-else>
          <div style="margin: 16px 0">
            <span style="color: #909399; margin-right: 12px;">请先选择合成批次</span>
            <el-select placeholder="请选择合成批次" v-model="selectedComposeBatch">
              <el-option
                v-for="batch in composeBatchList"
                :label="batch.name"
                :value="batch.id"
                :key="batch.id"
              />
            </el-select>
          </div>
          <div v-if="selectedComposeBatch">
            <div>合成开启/结束时间</div>
            <div>{{ selectedComposeBatchData.start_at_str }}</div>
            <div>{{ selectedComposeBatchData.end_at_str }}</div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="passDialogVisible = false">取消</el-button>
        <el-button v-if="auditType === 'new'" type="primary" @click="handlePass">
          通过审核
        </el-button>
        <el-button v-else type="primary" @click="handlePassCompose">通过审核</el-button>
      </span>
    </el-dialog>
    <el-dialog title="驳回原因填写" :visible.sync="visible" width="30%" @close="visible = false">
      <SelectRejectMsg
        username="批量"
        type="audit"
        :loading="rejectLoading"
        @confirm="handleReject"
        @cancel="visible = false"
        showBlockList
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  setNFTAuditRecord,
  getBatchList,
  auditNFT2ComposeBatch,
  getComposeBatches,
} from "@/api/nft.js";
import SelectRejectMsg from "@/components/SelectRejectMsg.vue";
import AuditCard from "./AuditCard.vue";
import dayjs from "dayjs";

export default {
  components: {
    SelectRejectMsg,
    AuditCard,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  filters: {
    dateFormater: function(value) {
      if (!value || +value === 0) {
        return "-";
      }
      return dayjs(+value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  computed: {
    selectedBatchData() {
      return this.batchList.find((i) => i.batch_number === this.selectedBatch);
    },
    selectedComposeBatchData() {
      return this.composeBatchList.find((i) => i.id === this.selectedComposeBatch);
    },
  },
  data() {
    return {
      passLoading: false,
      rejectLoading: false,
      visible: false,
      passDialogVisible: false,
      time: null,
      selectedBatch: null,
      batchList: [],

      selectedComposeBatch: null,
      composeBatchList: [],

      auditType: "new",
    };
  },
  methods: {
    async fetchBatchList() {
      try {
        const { data } = await getBatchList({
          page: 1,
          pageSize: 20,
        });
        this.batchList = data.data.items || [];
      } catch (error) {
        this.batchList = [];
      }
    },
    async fetchComposeBatchList() {
      try {
        const { data } = await getComposeBatches();
        this.composeBatchList = data.data.batches || [];
      } catch (error) {
        this.composeBatchList = [];
      }
    },
    handleClickPass() {
      this.passDialogVisible = true;
      this.fetchBatchList();
      console.log(1111);
      this.fetchComposeBatchList();
    },
    async handlePass() {
      if (this.passLoading) {
        return;
      }

      this.passLoading = true;
      try {
        // const ids = this.list.map((i) => i.record_id);
        const txids = this.list.map((i) => i.txid);
        const params = {
          txids,
          status: "pass",
          batch_number: this.selectedBatch,
        };
        await setNFTAuditRecord(params);
        this.$emit("success");

        this.passLoading = false;
        this.passDialogVisible = false;
      } catch (error) {
        this.passLoading = false;
      }
    },

    async handlePassCompose() {
      try {
        const ids = this.list.map((i) => i.record_id);
        const params = {
          on_shelf_ids: ids,
          batch_id: this.selectedComposeBatch,
        };

        await auditNFT2ComposeBatch(params);
        this.$emit("success");

        this.passLoading = false;
        this.passDialogVisible = false;
      } catch (error) {
        console.log(error);
      }
    },

    async handleReject(msg, isBlock) {
      if (this.rejectLoading) {
        return;
      }

      this.rejectLoading = true;

      try {
        // const ids = this.list.map((i) => i.record_id);
        const txids = this.list.map((i) => i.txid);
        const params = {
          txids,
          status: "reject",
          msg,
          in_black_list: isBlock,
        };
        await setNFTAuditRecord(params);
        this.$emit("success");
        this.rejectLoading = false;
        this.visible = false;
      } catch (error) {
        this.rejectLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
